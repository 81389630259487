/* eslint-disable */

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//import {DataFunctions} from './data/DataFunctions.js'
import DataSol from './data/DataSol.js'
import DataCon from './data/DataCon.js'
import DataNutritions from './data/DataNutritions.js'
import DataMol from './data/DataMol.js'
import VueSession from "vue-session";

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './element-variables.scss' 




Vue.use(ElementUI);
Vue.use(VueSession);
Vue.config.productionTip = false


//Vue.prototype.$DF = DataFunctions
// Vue.prototype.$current_el_sol = 'Glucose-1-phosphat'
Vue.prototype.$DS = DataSol
Vue.prototype.$DC = DataCon
Vue.prototype.$DN = DataNutritions
Vue.prototype.$DM = DataMol
Vue.prototype.$customFill = 0;


var vm = new Vue({
  el: '#app',
  router,
  store,  
  render: h => h(App)
}).$mount('#app')

