const DataMol = [];

DataMol['conNa'] = 23.0
DataMol['conK'] = 39.1
DataMol['conCa'] = 40.1
DataMol['conMg'] = 24.3
DataMol['conCl'] = 35.5
DataMol['conAcetat'] = 59.0
DataMol['conPhosphat'] = 95.0
DataMol['conAspartat'] = 132.1 
DataMol['conMalat'] = 115.1
DataMol['conHCO3'] = 61 //  
DataMol['conGlucosePhosphat'] = 260.1 
DataMol['conGlucose'] = 180.2 
DataMol['osmol'] = 0 

export default DataMol;