const DataSol = [
  { id: 11000, sel:0, nutCat: 'elo-iso',  cat: 'SolCatEloMel', name: 'ELO-MEL® isoton', package: '', size: 500, color: '#D7005C', img: 'elo_mel_isoton_kp_500.png' },
  { id: 11050, sel:0, nutCat: 'elo-iso',   cat: 'SolCatEloMel', name: 'ELO-MEL® isoton', package: '', size: 1000, color: '#D7005C', img: 'elo_mel_isoton_kp_1000.png'  },
  { id: 11100, sel:0, nutCat: 'elo-norm',   cat: 'SolCatEloMel', name: 'ELONORM®', package: '', size: 500, color: '#D7005C', img: 'elo_norm_kp_500.png' },
 // { id: 11150, sel:0, nutCat: 'elo-norm',   cat: 'SolCatEloMel', name: 'ELONORM®', package: '', size: 1000, color: '#D7005C', img: 'elo_norm_kp_1000.png' },
  { id: 11200, sel:0, nutCat: 'elo-op',   cat: 'SolCatEloMel', name: 'ELO-MEL® OP', package: '', size: 500, color: '#2E9E66', img: 'elo_norm_op_500.png' },
  { id: 11250, sel:0, nutCat: 'elo-op',   cat: 'SolCatEloMel', name: 'ELO-MEL® OP', package: '', size: 1000, color: '#2E9E66', img: 'elo_norm_op_1000.png' },
  { id: 11300, sel:0, nutCat: 'elo-opg',   cat: 'SolCatEloMel', name: 'ELO-MEL® OPG', package: '', size: 500, color: '#2E9E66', img: 'elo_norm_opg_500.png' },
  { id: 11350, sel:0, nutCat: 'elo-opg',   cat: 'SolCatEloMel', name: 'ELO-MEL® OPG', package: '', size: 1000, color: '#2E9E66', img: 'elo_norm_opg_1000.png' },
  { id: 11400, sel:0, nutCat: 'elo-semi-glu',   cat: 'SolCatEloMel', name: 'ELO-MEL® semiton mit Glucose', package: '', size: 500, color: '#19539E', img: 'elo_mel_kp_semiton_glucose_500.png' },
  { id: 11450, sel:0, nutCat: 'elo-semi-glu',   cat: 'SolCatEloMel', name: 'ELO-MEL® semiton mit Glucose', package: '', size: 1000, color: '#19539E', img: 'elo_mel_kp_semiton_glucose_1000.png'  },
  { id: 11500, sel:0, nutCat: 'elo-basis-glu',   cat: 'SolCatEloMel', name: 'ELO-MEL® basis mit Glucose', package: '', size: 500, color: '#FBBB2A', img: 'elo_mel_glucose_kp_500.png' },
  { id: 11600, sel:0, nutCat: 'elo-paed',   cat: 'SolCatEloMel', name: 'ELO-MEL® paediatric', package: '', size: 250, color: '#382F7B', img: 'elo_mel_paediatric_kp_250.png' },
  { id: 11700, sel:0, nutCat: 'elo-zell-forte',   cat: 'SolCatEloMel', name: 'ELOZELL® forte', package: '', size: 250, color: '#0A80CA', img: 'elo_zell_forte_glas_250.png'  },
  { id: 11800, sel:0, nutCat: 'elo-zell-spez',   cat: 'SolCatEloMel', name: 'ELOZELL® spezial', package: '', size: 250, color: '#EE7D07', img: 'elo_zell_spezial_glas_250.png'  },
 
  { id: 21000, sel:0, nutCat: 'sod',   cat: 'SolCatSodium', name: 'Kochsalz-Lösung ', package: 'Durchstechflasche', size: 50, color: '#EBD200', img: 'sod_durch_50.png' },

  { id: 21100, sel:0, nutCat: 'sod',   cat: 'SolCatSodium', name: 'Kochsalz-Lösung ', package: 'Glasflasche', size: 100, color: '#EBD200', img: 'sod_glas_100.png' },
  { id: 21300, sel:0, nutCat: 'sod',   cat: 'SolCatSodium', name: 'Kochsalz-Lösung ', package: 'Glasflasche', size: 250, color: '#EBD200', img: 'sod_glas_250.png' },
  { id: 21400, sel:0, nutCat: 'sod',   cat: 'SolCatSodium', name: 'Kochsalz-Lösung ', package: 'Glasflasche', size: 500, color: '#EBD200', img: 'sod_glas_500.png' },
  { id: 21500, sel:0, nutCat: 'sod',   cat: 'SolCatSodium', name: 'Kochsalz-Lösung ', package: 'Glasflasche', size: 1000, color: '#EBD200', img: 'sod_glas_1000.png' },

  { id: 22100, sel:0, nutCat: 'sod',   cat: 'SolCatSodium', name: 'Kochsalz-Lösung ', package: 'KabiPac', size: 100, color: '#EBD200', img: 'sod_kp_100.png' },
  { id: 22200, sel:0, nutCat: 'sod',   cat: 'SolCatSodium', name: 'Kochsalz-Lösung ', package: 'KabiPac', size: 200, color: '#EBD200', img: 'sod_kp_250.png' },
  { id: 22300, sel:0, nutCat: 'sod',   cat: 'SolCatSodium', name: 'Kochsalz-Lösung ', package: 'KabiPac', size: 250, color: '#EBD200', img: 'sod_kp_250.png' },
  { id: 22400, sel:0, nutCat: 'sod',   cat: 'SolCatSodium', name: 'Kochsalz-Lösung ', package: 'KabiPac', size: 500, color: '#EBD200', img: 'sod_kp_500.png' },
  { id: 22500, sel:0, nutCat: 'sod',   cat: 'SolCatSodium', name: 'Kochsalz-Lösung ', package: 'KabiPac', size: 1000, color: '#EBD200', img: 'sod_kp_1000.png' },

  { id: 23100, sel:0, nutCat: 'sod',   cat: 'SolCatSodium', name: 'Kochsalz-Lösung ', package: 'Freeflex', size: 50, color: '#EBD200', img: 'sod_ff_50.png' },
  { id: 23200, sel:0, nutCat: 'sod',   cat: 'SolCatSodium', name: 'Kochsalz-Lösung ', package: 'Freeflex', size: 100, color: '#EBD200', img: 'sod_ff_100.png' },
  { id: 23300, sel:0, nutCat: 'sod',   cat: 'SolCatSodium', name: 'Kochsalz-Lösung ', package: 'Freeflex', size: 250, color: '#EBD200', img: 'sod_ff_250.png' },
  { id: 23400, sel:0, nutCat: 'sod',   cat: 'SolCatSodium', name: 'Kochsalz-Lösung ', package: 'Freeflex', size: 500, color: '#EBD200', img: 'sod_ff_500.png' },
  { id: 23500, sel:0, nutCat: 'sod',   cat: 'SolCatSodium', name: 'Kochsalz-Lösung ', package: 'Freeflex', size: 1000, color: '#EBD200', img: 'sod_ff_1000.png' },

  { id: 24200, sel:0, nutCat: 'sod',   cat: 'SolCatSodium', name: 'Kochsalz-Lösung ', package: 'FFPlus', size: 100, color: '#EBD200', img: 'sod_ff_plus_100.png' },
  { id: 24300, sel:0, nutCat: 'sod',   cat: 'SolCatSodium', name: 'Kochsalz-Lösung ', package: 'FFPlus', size: 250, color: '#EBD200', img: 'sod_ff_plus_250.png' },
  { id: 24400, sel:0, nutCat: 'sod',   cat: 'SolCatSodium', name: 'Kochsalz-Lösung ', package: 'FFPlus', size: 500, color: '#EBD200', img: 'sod_ff_plus_500.png' },
  { id: 24500, sel:0, nutCat: 'sod',   cat: 'SolCatSodium', name: 'Kochsalz-Lösung ', package: 'FFPlus', size: 1000, color: '#EBD200', img: 'sod_ff_plus_1000.png' },


  { id: 31000, sel:0, nutCat: 'glu',   cat: 'SolCatGlucose', name: 'Glucose-5%-Lösung ', package: 'KabiPac', size: 250, color: '#0E8ACE', img: 'glu_kp_250.png' },
  { id: 31100, sel:0, nutCat: 'glu',   cat: 'SolCatGlucose', name: 'Glucose-5%-Lösung ', package: 'KabiPac', size: 500, color: '#0E8ACE', img: 'glu_kp_500.png' },
  { id: 32000, sel:0, nutCat: 'glu',   cat: 'SolCatGlucose', name: 'Glucose-5%-Lösung ', package: 'Freeflex', size: 500, color: '#0E8ACE', img: 'glu_ff_500.png' },
  { id: 32100, sel:0, nutCat: 'glu',   cat: 'SolCatGlucose', name: 'Glucose-5%-Lösung ', package: 'FFFPlus', size: 500, color: '#0E8ACE', img: 'glu_ff_plus_500.png' },
]
export default DataSol;


