<template>
  <div id="app">

    <app-header/>
    <router-view />

    <br /><br />
    <app-footer/>

  </div>
</template>


<script>
  import AppHeader from './components/layout/AppHeader'
  import AppFooter from './components/layout/AppFooter'
  
  export default {
    components: {
      'app-header': AppHeader,
      'app-footer': AppFooter
    }
  }
</script>



<style>
  h1,h2,h3 {
    color: #0063be;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  h1 {
    font-weight: 700;
    font-size: 30px;
    line-height: 1em;
  }

  h6 {
    font-size:1em;
  }

  #app {
    word-break: keep-all;
    color:#45525e;
    font-family:Verdana,Arial,sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
  }
  #nav {
    padding: 30px;
  }
  #nav a {
    color: #45525e;
    font-weight: bold;
  }
  #nav a.router-link-exact-active {
    color: #ff8f17;
  }

  .hr {
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid #ccc;
      margin: 1em 0;
      padding: 0;
  }  

  .boxed {
    margin-left:25px;
    margin-right:25px;
    min-width:420px;
    max-width:650px;
  }
</style>
