import Vue from 'vue'
import VueRouter from 'vue-router'
import Step1 from '../pages/PageSolSelect.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Step1',
    component: Step1
  },
  {
    path: '/step2',
    name: 'Step2',
    component: () => import(/* webpackChunkName: "step2" */ '../pages/PageConSelect.vue')
  },

  {
    path: '/datenschutz',
    name: 'Datenschutz',
    component: () => import(/* webpackChunkName: "datenschutz" */ '../pages/PageDatenschutz.vue')
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: () => import(/* webpackChunkName: "impressum" */ '../pages/PageImpressum.vue')
  },
  {
    path: '/rechtliches',
    name: 'Rechtliches',
    component: () => import(/* webpackChunkName: "rechtliches" */ '../pages/PageRechtliches.vue')
  },
  {
    path: '/step3',
    name: 'Step3',
    component: () => import(/* webpackChunkName: "step3" */ '../pages/PageResult.vue')
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
