<template>
    <el-steps :active="position" align-center finish-status="wait" id="ProgressState" class="boxed">
      <el-step @click.native="$router.push('/')" title="Lösung" description="auswählen" class="el_step"></el-step>
      <el-step @click.native="tryToGo('step2')" title="Konzentrat" description="hinzufügen" class="el_step"></el-step>
      <el-step @click.native="tryToGo('step3')" title="Ergebnis" description="anpassen" class="el_step"></el-step>
    </el-steps>
</template>

<script>
  export default {
    name: 'ProgressState',
    props: {
      position: Number
    },
    methods: {
      showMsg()
      {
        this.$notify({
          title: 'Lösung wählen',
          message: 'Bitte zuerst eine Lösung wählen!',
          duration: 2000,
          type: 'warning',
          showClose: false
        });
      },
      tryToGo(whereToGo)
      {
        if (whereToGo === "step2") { 
          if (this.hasSolSelected())
          {
            this.$router.push('/step2')
          } else {
            this.showMsg()
            this.$router.push('/')
          }
        } else if (whereToGo === "step3") {
          if (this.hasSolSelected())
          {
            this.$router.push('/step3')
          } else {
            this.showMsg()
            this.$router.push('/')
          }
        }
      },
      hasSolSelected(){
        var solSelected = false
        this.$DS.forEach((value, index) => {
          if (value.sel > 0) {
            solSelected = true
          }
        })
        return solSelected
      }
    }
  }
</script>

<style lang="scss" scoped>
  #ProgressState {
    margin-top:40px;
    margin-bottom:30px;
  }
  .el_step 
  {
    cursor: pointer;
  }
</style>