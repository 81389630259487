const DataNutritions = [];

DataNutritions['elo-iso'] = {
  ['conNa'] : 140,
  ['conK'] : 5,
  ['conCa'] : 2.5,
  ['conMg'] : 1.5,
  ['conCl'] : 108,
  ['conAcetat'] : 45,
  ['conPhosphat'] : 0,
  ['conAspartat'] : 0,
  ['conMalat'] : 0,
  ['conHCO3'] : 0,
  ['conGlucosePhosphat'] : 0,
  ['conGlucose'] : 0,
  ['osmol'] : 302,
}

DataNutritions['elo-norm'] = {
  ['conNa'] : 137,
  ['conK'] : 4,
  ['conCa'] : 0,
  ['conMg'] : 1.5,
  ['conCl'] : 100,
  ['conAcetat'] : 34,
  ['conPhosphat'] : 0,
  ['conAspartat'] : 0,
  ['conMalat'] : 0,
  ['conHCO3'] : 0,
  ['conGlucosePhosphat'] : 0,
  ['conGlucose'] : 0,
  ['osmol'] : 286.5,
}

DataNutritions['elo-op'] = {
  ['conNa'] : 100,
  ['conK'] : 18,
  ['conCa'] : 2,
  ['conMg'] : 3,
  ['conCl'] : 90,
  ['conAcetat'] : 38,
  ['conPhosphat'] : 0,
  ['conAspartat'] : 0,
  ['conMalat'] : 0,
  ['conHCO3'] : 0,
  ['conGlucosePhosphat'] : 0,
  ['conGlucose'] : 0,
  ['osmol'] : 251,
}


DataNutritions['elo-opg'] = {
  ['conNa'] : 100,
  ['conK'] : 18,
  ['conCa'] : 2,
  ['conMg'] : 3,
  ['conCl'] : 94,
  ['conAcetat'] : 38,
  ['conPhosphat'] : 0,
  ['conAspartat'] : 0,
  ['conMalat'] : 0,
  ['conHCO3'] : 0,
  ['conGlucosePhosphat'] : 0,
  ['conGlucose'] : 277.5, 
  ['osmol'] : 528,
}


DataNutritions['elo-semi-glu'] = {
  ['conNa'] : 70,
  ['conK'] : 2.5,
  ['conCa'] : 1.25,
  ['conMg'] : 0.75,
  ['conCl'] : 76.5,
  ['conAcetat'] : 0,
  ['conPhosphat'] : 0,
  ['conAspartat'] : 0,
  ['conMalat'] : 0,
  ['conHCO3'] : 0,
  ['conGlucosePhosphat'] : 0,
  ['conGlucose'] : 277.5,
  ['osmol'] : 428,
}


DataNutritions['elo-basis-glu'] = {
  ['conNa'] : 45,
  ['conK'] : 25,
  ['conCa'] : 0,
  ['conMg'] : 2.5,
  ['conCl'] : 65,
  ['conAcetat'] : 0,
  ['conPhosphat'] : 10,
  ['conAspartat'] : 0,
  ['conMalat'] : 0,
  ['conHCO3'] : 0,
  ['conGlucosePhosphat'] : 0,
  ['conGlucose'] : 277.5, 
  ['osmol'] : 425,
}


DataNutritions['elo-paed'] = {
  ['conNa'] : 140,
  ['conK'] : 4,
  ['conCa'] : 1,
  ['conMg'] : 1,
  ['conCl'] : 118,
  ['conAcetat'] : 30,
  ['conPhosphat'] : 0,
  ['conAspartat'] : 0,
  ['conMalat'] : 0,
  ['conHCO3'] : 0,
  ['conGlucosePhosphat'] : 0,
  ['conGlucose'] : 55.5,
  ['osmol'] : 351,
}


DataNutritions['elo-zell-forte'] = {
  ['conNa'] : 0,
  ['conK'] : 48,
  ['conCa'] : 0,
  ['conMg'] : 24,
  ['conCl'] : 0,
  ['conAcetat'] : 0,
  ['conPhosphat'] : 0,
  ['conAspartat'] : 96,
  ['conMalat'] : 0,
  ['conHCO3'] : 0,
  ['conGlucosePhosphat'] : 0,
  ['conGlucose'] : 0,
  ['osmol'] : 274,
}


DataNutritions['elo-zell-spez'] = {
  ['conNa'] : 0,
  ['conK'] : 96,
  ['conCa'] : 0,
  ['conMg'] : 24,
  ['conCl'] : 64,
  ['conAcetat'] : 0,
  ['conPhosphat'] : 0,
  ['conAspartat'] : 80,
  ['conMalat'] : 0,
  ['conHCO3'] : 0,
  ['conGlucosePhosphat'] : 0,
  ['conGlucose'] : 0,
  ['osmol'] : 240,
}

/*
DataNutritions['perflux'] = {
  ['conNa'] : 125,
  ['conK'] :12,
  ['conCa'] : 1.5,
  ['conMg'] : 0.75,
  ['conCl'] : 135,
  ['conAcetat'] : 5,
  ['conPhosphat'] : 0,
  ['conAspartat'] : 0,
  ['conMalat'] : 0,
  ['conHCO3'] : 0,
  ['conGlucosePhosphat'] : 0.75,
  ['conGlucose'] : 0,
  ['osmol'] : 280,
}
*/
// TODO Prüfen lassen!!!!
DataNutritions['sod'] = {
  ['conNa'] : 154,
  ['conK'] : 0,
  ['conCa'] : 0,
  ['conMg'] : 0,
  ['conCl'] : 154,
  ['conAcetat'] : 0,
  ['conPhosphat'] : 0,
  ['conAspartat'] : 0,
  ['conMalat'] : 0,
  ['conHCO3'] : 0,
  ['conGlucosePhosphat'] : 0,
  ['conGlucose'] : 0,
  ['osmol'] : 308,
}

DataNutritions['glu'] = {
  ['conNa'] : 0,
  ['conK'] : 0,
  ['conCa'] : 0,
  ['conMg'] : 0,
  ['conCl'] : 0,
  ['conAcetat'] : 0,
  ['conPhosphat'] : 0,
  ['conAspartat'] : 0,
  ['conMalat'] : 0,
  ['conHCO3'] : 0,
  ['conGlucosePhosphat'] : 0,
  ['conGlucose'] : 277.5,  
  ['osmol'] : 277.5,
}



DataNutritions['con-cal'] = {
  ['conNa'] : 0,
  ['conK'] : 0,
  ['conCa'] : 223, 
  ['conMg'] : 0,
  ['conCl'] : 0,
  ['conAcetat'] : 0,
  ['conPhosphat'] : 0,
  ['conAspartat'] : 0,
  ['conMalat'] : 0,
  ['conHCO3'] : 0,
  ['conGlucosePhosphat'] : 0,
  ['conGlucose'] : 0,
  ['osmol'] : 0,
}



DataNutritions['con-glu-phos'] = {
  ['conNa'] : 2000,
  ['conK'] : 0,
  ['conCa'] : 0,
  ['conMg'] : 0,
  ['conCl'] : 0,
  ['conAcetat'] : 0,
  ['conPhosphat'] : 1000,
  ['conAspartat'] : 0,
  ['conMalat'] : 0,
  ['conHCO3'] : 0,
  ['conGlucosePhosphat'] : 1000,
  ['conGlucose'] : 0,
  ['osmol'] : 0,
}



DataNutritions['con-kal-chl'] = {
  ['conNa'] : 0,
  ['conK'] : 1000,
  ['conCa'] : 0,
  ['conMg'] : 0,
  ['conCl'] : 1000,
  ['conAcetat'] : 0,
  ['conPhosphat'] : 0,
  ['conAspartat'] : 0,
  ['conMalat'] : 0,
  ['conHCO3'] : 0,
  ['conGlucosePhosphat'] : 0,
  ['conGlucose'] : 0,
  ['osmol'] : 0,
}


DataNutritions['con-kal-l-mal'] = {
  ['conNa'] : 0,
  ['conK'] : 1000,
  ['conCa'] : 0,
  ['conMg'] : 0,
  ['conCl'] : 0,
  ['conAcetat'] : 0,
  ['conPhosphat'] : 0,
  ['conAspartat'] : 0,
  ['conMalat'] : 1000,
  ['conHCO3'] : 0,
  ['conGlucosePhosphat'] : 0,
  ['conGlucose'] : 0,
  ['osmol'] : 0,
}


DataNutritions['con-na-cl'] = {
  ['conNa'] : 1000,
  ['conK'] : 0,
  ['conCa'] : 0,
  ['conMg'] : 0,
  ['conCl'] : 1000,
  ['conAcetat'] : 0,
  ['conPhosphat'] : 0,
  ['conAspartat'] : 0,
  ['conMalat'] : 0,
  ['conHCO3'] : 0,
  ['conGlucosePhosphat'] : 0,
  ['conGlucose'] : 0,
  ['osmol'] : 0,
}


DataNutritions['con-na-carb'] = {
  ['conNa'] : 1000,
  ['conK'] : 0,
  ['conCa'] : 0,
  ['conMg'] : 0,
  ['conCl'] : 0,
  ['conAcetat'] : 0,
  ['conPhosphat'] : 0,
  ['conAspartat'] : 0,
  ['conMalat'] : 0,
  ['conHCO3'] : 1000, 
  ['conGlucosePhosphat'] : 0,
  ['conGlucose'] : 0,
  ['osmol'] : 0,
}
export default DataNutritions;
