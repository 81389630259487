<template>


  <div align="center">

    <div v-if="this.$session.get('welcomMsgShow') == true">
      <el-alert 
        style="margin-top:20px;"
        title="Willkommen"
        type="info">
        <div align="center">
          <br />Herzlich Willkommen beim EloCalc von Fresenius Kabi Austria GmbH!<br /><br />
          Der EloClac ist ein praktisches Rechentool mit dem Sie schnell und unkompliziert die Zusammensetzung von Infusionsmischungen berechnen können.<br /><br />
          Wählen Sie dazu einfach die gewünschte Infusionslösung aus und fügen Sie die gewünschten Elektrolytkonzentrate hinzu. Das Tool berechnet dann automatisch die Zusammensetzung der Infusionsmischung und stellt diese in Form einer Tabelle dar.<br /><br />
          Der Elocalc kann auch offline oder in Räumen mit schlechtem Signal wie zum Beispiel im OP Saal verwendet werden. Eine Internetverbindung ist lediglich beim ersten Laden der Seite notwendig. Ist die Seite einmal geladen, können Sie das Tool auch ohne bestehende Internet Verbindung verwenden.<br /><br />
          Wir wünschen Ihnen viel Erfolg!<br /><br />
        </div>
      </el-alert>
    </div>


    <ProgressState :position="0"/>
    <el-tabs type="border-card" class="boxed">
      <el-tab-pane label="Elo-Mele">        
        <span v-for="ds in this.$DS.filter(function(item){return item.cat.match(/SolCatEloMel/)})" :key="ds.id">
          <el-tooltip placement="right" effect="light" style="margin-right:50px;">  
            <div slot="content">
              <img height="120" :src="getImgUrl(ds)">       
            </div>                
           
            <el-button v-if="ds.color === '#FBBB2A'" class="solListSod" @click="performCli(ds)" :style="{backgroundColor:ds.color}">
              {{ ds.name }} - {{ ds.size }} ml<br />
            </el-button>
            <el-button v-else class="solList" @click="performCli(ds)" :style="{backgroundColor:ds.color}">
              {{ ds.name }} - {{ ds.size }} ml<br />
            </el-button>

          </el-tooltip>
        </span>
      </el-tab-pane>

      <el-tab-pane label="Kochsalz">

        <el-collapse>
          <el-collapse-item>
            <template slot="title">
                <img style="float:right;margin-right:15px;" height="35" src="@/assets/img/packSol/sod_durch_50.png"/>
                <h4 style="color:#606266">Durchstechflasche</h4>
            </template>            
            <span v-for="ds in this.$DS.filter(function(item){return item.cat.match(/SolCatSodium/)}).filter(function(item){return item.package.match(/Durchstechflasche/)})" :key="ds.id">
              <el-tooltip placement="right" effect="light" style="margin-right:50px;">  
                <div slot="content">
                  <img height="120" :src="getImgUrl(ds)">       
                </div>                
                <el-button class="solListSod" @click="performCli(ds)" :style="{backgroundColor:ds.color}">
                  {{ ds.name }} - {{ ds.size }} ml<br />
                </el-button>
              </el-tooltip>
            </span>
          </el-collapse-item>

          <el-collapse-item>
            <template slot="title" style="height:200px !important;">
                <img style="float:right;margin-right:18px;" height="35" src="@/assets/img/packSol/sod_glas_100.png"/>
                <h4 style="color:#606266">Glasflasche</h4>
            </template>            
            <span v-for="ds in this.$DS.filter(function(item){return item.cat.match(/SolCatSodium/)}).filter(function(item){return item.package.match(/Glasflasche/)})" :key="ds.id">
              <el-tooltip placement="right" effect="light" style="margin-right:50px;">  
                <div slot="content">
                  <img height="120" :src="getImgUrl(ds)">       
                </div>                
                <el-button class="solListSod" @click="performCli(ds)" :style="{backgroundColor:ds.color}">
                  {{ ds.name }} - {{ ds.size }} ml<br />
                </el-button>
              </el-tooltip>
            </span>
          </el-collapse-item>


          <el-collapse-item>
            <template slot="title" style="height:200px !important;">
                <img style="float:right;margin-right:14px;" height="35" src="@/assets/img/packSol/sod_kp_100.png"/>
                <h4 style="color:#606266">KabiPac</h4>
            </template>            
            <span v-for="ds in this.$DS.filter(function(item){return item.cat.match(/SolCatSodium/)}).filter(function(item){return item.package.match(/KabiPac/)})" :key="ds.id">
              <el-tooltip placement="right" effect="light" style="margin-right:50px;">  
                <div slot="content">
                  <img height="120" :src="getImgUrl(ds)">       
                </div>                
                <el-button class="solListSod" @click="performCli(ds)" :style="{backgroundColor:ds.color}">
                  {{ ds.name }} - {{ ds.size }} ml<br />
                </el-button>
              </el-tooltip>
            </span>
          </el-collapse-item>

          <el-collapse-item>
            <template slot="title" style="height:200px !important;">
                <img style="float:right;margin-right:22px;" height="35" src="@/assets/img/packSol/sod_ff_100.png"/>
                <h4 style="color:#606266">Freeflex</h4>
            </template>            
            <span v-for="ds in this.$DS.filter(function(item){return item.cat.match(/SolCatSodium/)}).filter(function(item){return item.package.match(/Freeflex/)})" :key="ds.id">
              <el-tooltip placement="right" effect="light" style="margin-right:50px;">  
                <div slot="content">
                  <img height="120" :src="getImgUrl(ds)">       
                </div>                
                <el-button class="solListSod" @click="performCli(ds)" :style="{backgroundColor:ds.color}">
                  {{ ds.name }} - {{ ds.size }} ml<br />
                </el-button>
              </el-tooltip>
            </span>
          </el-collapse-item>

          <el-collapse-item>
            <template slot="title" style="height:200px !important;">
                <img style="float:right;margin-right:22px;" height="35" src="@/assets/img/packSol/sod_ff_plus_100.png"/>
                <h4 style="color:#606266">Freeflex +</h4>
            </template>            
            <span v-for="ds in this.$DS.filter(function(item){return item.cat.match(/SolCatSodium/)}).filter(function(item){return item.package.match(/FFPlus/)})" :key="ds.id">
              <el-tooltip placement="right" effect="light" style="margin-right:50px;">  
                <div slot="content">
                  <img height="120" :src="getImgUrl(ds)">       
                </div>                
                <el-button class="solListSod" @click="performCli(ds)" :style="{backgroundColor:ds.color}">
                  {{ ds.name }} - {{ ds.size }} ml<br />
                </el-button>
              </el-tooltip>
            </span>
          </el-collapse-item>


        </el-collapse> 


      </el-tab-pane>

      <el-tab-pane label="Glucose">

        <el-collapse>
          <el-collapse-item>
            <template slot="title" style="height:200px !important;">
                <img style="float:right;margin-right:16px;" height="35" src="@/assets/img/packSol/glu_kp_250.png"/>
                <h4 style="color:#606266">KabiPac</h4>
            </template>            
            <span v-for="ds in this.$DS.filter(function(item){return item.cat.match(/SolCatGlucose/)}).filter(function(item){return item.package.match(/KabiPac/)})" :key="ds.id">
              <el-tooltip placement="right" effect="light" style="margin-right:50px;">  
                <div slot="content">
                  <img height="120" :src="getImgUrl(ds)">       
                </div>                
                <el-button class="solList" @click="performCli(ds)" :style="{backgroundColor:ds.color}">
                  {{ ds.name }} - {{ ds.size }} ml<br />
                </el-button>
              </el-tooltip>
            </span>
          </el-collapse-item>

          <el-collapse-item>
            <template slot="title" style="height:200px !important;">
                <img style="float:right;margin-right:15px;" height="35" src="@/assets/img/packSol/glu_ff_500.png"/>
                <h4 style="color:#606266">Freeflex</h4>
            </template>            
            <span v-for="ds in this.$DS.filter(function(item){return item.cat.match(/SolCatGlucose/)}).filter(function(item){return item.package.match(/Freeflex/)})" :key="ds.id">
              <el-tooltip placement="right" effect="light" style="margin-right:50px;">  
                <div slot="content">
                  <img height="120" :src="getImgUrl(ds)">       
                </div>                
                <el-button class="solList" @click="performCli(ds)" :style="{backgroundColor:ds.color}">
                  {{ ds.name }} - {{ ds.size }} ml<br />
                </el-button>
              </el-tooltip>
            </span>
          </el-collapse-item>

          <el-collapse-item>
            <template slot="title" style="height:200px !important;">
                <img style="float:right;margin-right:15px;" height="35" src="@/assets/img/packSol/glu_ff_plus_500.png"/>
                <h4 style="color:#606266">Freeflex +</h4>
            </template>            
            <span v-for="ds in this.$DS.filter(function(item){return item.cat.match(/SolCatGlucose/)}).filter(function(item){return item.package.match(/FFPlus/)})" :key="ds.id">
              <el-tooltip placement="right" effect="light" style="margin-right:50px;">  
                <div slot="content">
                  <img height="120" :src="getImgUrl(ds)">       
                </div>                
                <el-button class="solList" @click="performCli(ds)" :style="{backgroundColor:ds.color}">
                  {{ ds.name }} - {{ ds.size }} ml<br />
                </el-button>
              </el-tooltip>
            </span>
          </el-collapse-item>
        </el-collapse>

      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
  import ProgressState from '@/components/ProgressState.vue'
  export default {
    components: {
      ProgressState
    },    
    methods: {
      showWelcomeMsg()
      {

        this.$session.start();
        
        if ( this.$session.get('welcomMsgShown') ) {
          this.$session.set('welcomMsgShow', false);          
        } else
        {
          this.$session.set('welcomMsgShow', true);          
          this.$session.set('welcomMsgShown', true);
        }
      

        /*this.$notify({
          title: 'Willkommen',
          message: 'Bitte zuerst eine\rTest\r\n\r\nSSS',
          duration: 10000,
          type: 'info',
          showClose: true
        });*/
      },
      performCli(obj) {
        //this.$DS[1].sel="1";
        this.selSol(obj);
        this.$router.push('step2');
      },
      selSol(obj) {
        this.resetSol();
        obj.sel = 1;
        if (obj.cat === "SolCatEloMel") {
          this.$DC.forEach((value, index) => {
            if (!value.combineWithElo) {
              value.sel=0;
            }
          });          
        }
      },
      resetSol(){
        this.$DS.forEach((value, index) => {
          value.sel=0;
        });
      },
      getImgUrl(obj)
      {
        return require('@/assets/img/packSol/' + obj.img)
      },
    },
    created(){
      this.showWelcomeMsg()
    },
  }
</script>


<style scoped>
  .el-collapse-item__header1
  {
    height: 200px !important;
  }
  .solList {    
    color:white;    
    cursor:pointer;
    width:95%;
    padding:10px;
    padding-top:15px;
    padding-bottom:15px;
    margin-left:10px;
    margin-right:10px;
    margin-bottom:5px;
    margin-top:5px;
    font-weight:600;
    font-size:15px;
  }

  .solList:hover { 
    color: white;
  }  

  .solListSod {    
    color: #444;
    cursor:pointer;
    width:95%;
    padding:10px;
    padding-top:15px;
    padding-bottom:15px;
    margin-left:10px;
    margin-right:10px;
    margin-bottom:5px;
    margin-top:5px;
    font-weight:600;
    font-size:15px;
  }

  .solListSod:hover { 
    color: #444;

  }  
</style>